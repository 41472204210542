@import url("./antd-overwrite.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ReactCollapse--collapse {
  transition: all ease 0.3s;
}

.custom-player {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;
  border-radius: 2px;
  min-height: 100px;
  margin-bottom: 5px;
}


.react-player-yt {
  width: 100%;
  height: auto !important;
  border-radius: 2px;
  aspect-ratio: 16/9;
}
 
.controls {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  /*Stack the progress bar and time display*/
  padding: 0 10px;
}

.controls-left,
.controls-right {
  display: flex;

  align-items: center;
}

.controls-left button,
.controls-right button { 
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px; 
}

.seconds span {
  font-size: 9px;
}

.seconds img {
  margin-right: -35px;
  /* Adjust the spacing as needed */

}

.expand-compress {
  /* background: rgba(0, 0, 0, 0.6); */
  color: white
}

.progress-bar {
  width: 100%;
  margin: 5px 0;
  /* Add margin to separate from other controls */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Space out slider and time display */
}

.progress-slider {
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  height: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.progress-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

/* .progress-slider::-moz-range-thumb {
    background: white;
    width: 15px;
    height: 15px;
    border-radius: 50%;
  } */

.progress-time {
  font-size: 14px;
  /* background: rgba(0, 0, 0, 0.6); */
  color: white;
  padding: 10px
}

.controls-row {
  justify-content: space-between;
  display: flex;
  /* background:red; */
}
.react-player {
  background-color: black; /* Background for the player */
  padding: 2px;
  width: 100%;
  height: auto !important;
  border-radius: 2px; 
  
}
.react-player-wrapper {
    background-color: black; /* Background for the player */
    color: white; /* Ensure text stays dark */
    color-scheme: only light;
 } 
.data-theme-bg {
  background-color: gray;
}

.dark-theme > div > div > div {
  background-color: gray;
}

.dark-theme-for-edge > div > div::before {
  background: gray !important;
}
/* Audio component  */
.rhap_container {
  border-radius: 10px;
  margin-bottom: 5px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
          line-clamp: 2 !important;
  -webkit-box-orient: vertical;
}
.ant-form-item-required::before{
  width: 10px;
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 12px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  width: 10px;
  text-align: center; 
}
.ant-picker-dropdown {
  overflow: visible !important;
  z-index: 9999999;
}
.ant-picker-time-panel-column{ overflow-y: scroll !important; }

